<template>
  <div class="agreement wrapper">
    <div class="p-content">
      <h1>Contact us:</h1>
      <p>
        If you have any questions, complaints or claims with respect to our Services, you may contact us at <a href="mailto:lynnpatient@gmail.com">lynnpatient@gmail.com</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>
<style lang="less">
  @import "~@/assets/styles/agreement.less";
</style>
<style lang="less" scoped>
  .wrapper{
    padding: 0 5%;
    background:#F0F0F0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    min-height: 100vh;
    box-sizing: border-box;
  }
</style>
